@charset "UTF-8";
.event-container {background:url(../images/event/backNet.gif) top left repeat-x; width:100%; margin-bottom:10px;}
.catalog-product-view {
	
	.top-container {padding:0px 10px;}
	.event-container {margin:20px 0 10px 0;}
	.discountEvent {margin-top:10px;}
	
}
.counterEvent img {with:378px; height:170px; margin:0 auto; text-align:center;}
.event-container .event {background:$c-black; width:100%; margin-top:1px; color:#FFF; text-align:center; padding:5px 0; text-transform:uppercase;}
.event-container .event .container {font-size:30px; color:$c-rose;}
.discountEvent {background:$c-rose; padding:2px 0; text-align:center; color:$c-black; text-transform:uppercase; font-size:14px;}
.discountEvent .disc-percent {font-size:18px;}
.product-info .eventPriceMsrp {font-size:12px; font-style: italic; text-align:center; width:100%; display:block; margin-top:2px;}

// EVENT STATUS
.event.upcoming .event-dates {font-size:20px; color:$c-rose;}

@include bp(max-width, $bp-medium) {
	.event-container {
		margin:10px 0;
	}
	.counterEvent {background-position:top center;}

}
@include bp(max-width, $bp-small) {
	.counterEvent img {width:100%; height:inherit;}
	.event-container {
		background:none;
	}
}